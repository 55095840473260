import axios from 'axios'
import { baseURL } from './config'

import { ElMessage } from 'element-plus'

import { getAccessToken } from '@/utils/auth';
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API,
	baseURL,
	// 超时（毫秒）
	timeout: 300000
})

// request拦截器
service.interceptors.request.use(config => {
	// uni.showLoading({
	// 	title: '加载中'
	// });

	let imgToken = localStorage.getItem("imgToken");
	if (imgToken) {
		config.headers['image-token'] = imgToken;
	}
	if (getAccessToken()) {
		config.headers.token = getAccessToken()
		config.headers.platform = 'pc'
	}
	return config
}, error => {
	ElMessage(error);
	Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => { // server error  http status === 200
	// uni.hideLoading();
	if (res.data.code !== 1) { // api fail
		// uni.showLoading({ 
		// 	title: res.msg
		// });
		return Promise.reject(res)
	}
	return Promise.resolve(res)
},
	error => { // http error  404 403  503 ，网络错误
		// uni.hideLoading();
		ElMessage(error);
		return Promise.reject(error)
	}
)


export default service