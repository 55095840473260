<template>
  <div class="common-layout">
    <el-container class="fff">
      <!-- <el-header> -->
      <div class="flex header-box">
        <div class="sys-title" :style="{ 'background-image': `url(${logo})` }">
        </div>
        <div class="header-right flex">
          <div class="avatar-img">
            <img class="img" :src="store.state.user.avatar" alt="" />
          </div>
          <el-dropdown>
            <span class="el-dropdown-link">{{ store.state.user.nickname }}</span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="goLogout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <!-- </el-header> -->
    </el-container>
    <el-container>
      <div class="fff nav-bg flex">
        <div class="flex nav-box">
          <el-menu
            :default-active="router.currentRoute.value.path == '/hotel/logs' || router.currentRoute.value.path == '/hotel/add' ? '/hotel' : router.currentRoute.value.path == '/orders/detail' ? '/orders' : router.currentRoute.value.path"
            class="el-menu-demo" mode="horizontal" :ellipsis="false">
            <template v-for="(item, index) in store.state.permission.addRoutes" :key="index">
              <template v-if="item.children && item.children.length > 1">
                <el-sub-menu :index="item.path">
                  <template #title>{{ item.meta.title }}</template>
                  <el-menu-item :index="item.path + '/' + childItem.path"
                    @click="chouseCurrentNav(index, item.path + '/' + childItem.path)"
                    v-for="(childItem, chilIndex) in item.children" :key="chilIndex">
                    {{ childItem.meta.title }}</el-menu-item>
                </el-sub-menu>
              </template>
              <template v-else>
                <el-menu-item v-if="item.meta.title" :index="item.path" @click="chouseCurrentNav(index, item.path)"
                  :router="item.path">
                  {{ item.meta.title }}
                </el-menu-item>
              </template>
            </template>
          </el-menu>
        </div>
      </div>
      <el-container>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { ElMessageBox } from "element-plus";
import { logout } from "@/api/login";
import { removeToken } from "@/utils/auth";
import logo from "@/assets/images/logo.png";

const router = useRouter();
const store = useStore();
onMounted(() => {
  console.log("所有路由=", router.getRoutes());
});

let chouseCurrentNav = (index, path) => {
  console.log(path);
  router.push({ path });
};


let goLogout = () => {
  ElMessageBox.confirm("确定注销并退出系统吗？", "提示")
    .then(() => {
      logout().then(() => {
        removeToken();
        window.localStorage.removeItem('HotelID')
        localStorage.removeItem("permissionsBtn")
        router.push({ path: "/login" });
      });
    })
    .catch(() => { });
};
</script>
<style lang="scss" scoped>
:deep(.el-menu--horizontal.el-menu) {
  border-bottom: 2px solid #fff !important;
}

:deep(.el-menu--horizontal > .el-menu-item.is-active) {
  border-bottom: 2px solid #fff !important;
}

:deep(.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title) {
  border-bottom: 2px solid #fff !important;
}

.header-box {
  width: 1359px;
  z-index: 1;
  justify-content: space-between;
  margin: 20px auto;

  .sys-title {
    z-index: 1;
    width: 120px;
    height: 70px;
    background-size: 120px 70px;
    overflow: hidden;
  }
}

.nav-bg {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #e9e9e9;
}

.header-right {
  font-size: 16px;
  color: #666;

  .avatar-img {
    width: 40px;
    height: 40px;
    margin-right: 24px;
    border-radius: 50%;
    background: #f4f4f5;

    .img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}

.nav-box {
  width: 1359px;
  height: 100%;
  margin: 0 auto;

  .nav-list {
    margin-right: 50px;
    font-weight: 500;
    font-size: 16px;
    color: #b0b7c3;
  }

  .active {
    color: #2772f0;
  }
}

:deep(.el-menu--horizontal .el-menu-item:not(.is-disabled):hover) {
  background-color: #fff !important;
}

:deep(.el-menu--horizontal .el-menu-item:not(.is-disabled):focus) {
  background-color: #fff !important;
}

:deep(.el-menu--horizontal>.el-sub-menu.is-active .el-sub-menu__title) {
  color: #2772f0 !important;
}

:deep(.el-menu-item) {
  padding: 0;
  margin-right: 30px !important;
}

// :deep(.el-menu-item:first-child) {
//   padding: 0 !important;
// }</style>
